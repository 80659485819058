@use "../../../styles/vars/vars.scss" as v;

.config__header--space{
  display: block;
  width: 100%;
}
.config{
background-color: v.$color-background;
}
.cajatemas__header{
  display: flex;
}
.config__options{
  width: 100%;
  max-width: none;
}
.config__modal {
  width: 100%;
}
.config__container{
  display: block;
}
.configoptions__option{
  padding-left: 7%;
}