@use "../../../vars/vars.scss" as v;

.score__user {
    border: 2px solid v.$color-letra;
    background-color: v.$color-claro;
    border-radius: 0.6rem;
    position: absolute;
    top: 5rem;
    right: 0.2rem;
    width: 90%;
    height: auto;
    padding: 1rem;
    z-index: 15;
    a{
        text-decoration: none;
        color: v.$color-oscuro;


    }
    &--box {
        display: flex;
        padding: 0.4rem;
        align-items: center;
        border-radius: 1rem;
        p {
            width: 100%;
            font-size: v.$font-letra;
            color: v.$color-oscuro;
            text-decoration: none;
        }
   
        &:hover {
            background-color: v.$color-bordes;
        }
    }
}
.config--arrow {
    width: 4rem;
    height: 2.4rem;
    path {
        fill: v.$color-letra;
    }
}
.config__svg {
    width: 5rem;
    height: 4rem;
    background-color: v.$color-claro;
    border-radius: 3rem;
    margin-right: 2rem;
    path {
        fill: v.$color-secndario-svgs;
    }
}
.score__user--closemodal{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 14;
}