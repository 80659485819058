@use "../../../vars/vars.scss" as v;

.score--container {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  position: sticky;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
  z-index: -1;
  height: 50%;
  padding-bottom: 0;
}
.items {
  display: flex;
  width: 100%;
  height: 7rem;
  background-color: v.$color-claro;
  flex-wrap: wrap;
  border-bottom: 1px solid v.$color-bordes;
  flex-direction: row;
  div {
    color: v.$color-letra;
    p {
      font-size: 1.4rem;
      width: 100%;
    }
  }
  a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-decoration: none;
    width: 82%;
    height: 100%;
  }
  &__skeleton {
    width: 100%;
    display: flex;
    height: 9rem;
    background-color: white;
    padding: 0.5rem;
    &--button {
      padding: 0.3rem;

      width: 15%;
    }
    &--date {
      padding: 0.3rem;

      width: 30%;
    }
    &--info {
      padding: 0.3rem;

      width: 60%;
    }
  }
  &__container {
    width: 85%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__button {
    width: 15%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: v.$color-botones;
      color: v.$color-botones-text;
      width: 70%;
      height: 30%;
      max-width: 4rem;
      border: none;
      border-radius: 0.5rem;
      font-size: v.$font-letra;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    width: 35%;
    height: 60%;
  }
  &__name {
    display: flex;
    align-items: center;
    width: 65%;
    height: 60%;
  }
  &__hour {
    display: flex;
    align-items: center;
    width: 35%;
    height: 40%;
  }
  &--svgs {
    display: flex;
    width: 65%;
    height: 40%;
    figure {
      width: 6rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &__pdf {
    display: flex;
    width: 18%;
    height: 100%;
    figure {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 5rem;
        height: 60%;
      }
    }
  }

  .svg-active {
    fill: v.$color-secndario-svgs;
  }
  .svg-not {
    fill: v.$color-notactive;
  }
  .Score__landscape {
    display: none;
  }
}
.items__link {
  width: 80%;
  border: none;
  background-color: white;
  text-align: left;
  display: flex;
}
.items__nodata {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  padding-top: 5rem;
  height: 100%;
  font-size: v.$font-letra;
  text-align: center;
  color: v.$color-letra;
}
.fa-rejected {
  color: v.$color-peligro;
}
.fa-approved path {
  fill: v.$color-secndario-svgs;
}

@media (max-height: 505px) and (orientation: landscape) {
  .Mobile__intento {
    display: none;
  }
  .Score__landscape {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: v.$font-letra;
    flex-direction: column;
    color: v.$color-letra;
    img {
      width: 5rem;
    }
  }
}
