@use "../../../../vars/vars.scss" as v;

.profile--info {
  padding-top: 1%;
}
.expediente--mapa {
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  padding: 0 2%;
  
}
.expediente--notfound{
  font-size: v.$font-letra-big;
  color: v.$color-letra;
  padding: 2%;
  padding-left:3%;
}
.expediente {
  display: flex;
  flex-direction: column;
  width: 100%;
  &--tabla {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    height: auto;
    
    border-bottom: 1px solid v.$color-bordes;
    padding: 0 2%;
    &--izquierda {
      width: 49%;
      height: auto;
      max-height: 15rem;
      div {
        width: 100%;
        height: auto;
        padding: 2rem 0;
        display: flex;
        padding-left: 2%;
        font-size: v.$font-letra;
        color: v.$color-letra;

        flex-direction: column;
        p {
          width: 100%;
          font-size: v.$font-letra;
        }
        a {
          margin-top: 1rem;
          text-decoration: none;
          color: v.$color-secndario;
        }
      }
    }
    &--derecha {
      width: 50%;
      height: 34%;
    }
  }
  &--cajita {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2%;
  }
  &--documentos--img {
    width: 100%;
  }
}
.proccedings__right {
  width: 100%;
  height: 50%;
  padding: 2rem 0;
  display: flex;
  font-size: v.$font-letra;
  color: v.$color-letra;
  flex-direction: column;
  p {
    width: 100%;
    font-size: v.$font-letra;
    padding-left: 0;
    overflow-wrap: break-word;
  }
  a {
    margin-top: 1rem;
    text-decoration: none;
    color: v.$color-secndario;
  }
}
.proceedings--map {
  height: 25rem;
  width: 100%;
  padding: 0 5%;
  padding-bottom: 7%;
}
@media (min-width: 597px) {
  @import "./expediente--medium";
}
@media (min-width: 992px) {
  @import "./expediente--large";
}
