@use "../../../vars/vars.scss" as v;

.filter {
  &--date {
    margin-bottom: 0;
  }
  &--box {
    font-size: v.$font-letra;
  }
  padding-top: 1rem;
  &--box {
    padding: 1.2rem;
    height: 100%;
    font-size: v.$font-letra;
  }
  &--calendary {
    width: 100%;
    z-index: 11;
    position: absolute;
    top: 23rem;
    left: 0;
    margin: 0 auto;
    div {
      width: 80%;
      margin: 0 auto;
      height: 3.5rem;
      input {
        color: v.$color-letra;
        font-size: v.$font-letra-small;
        font-weight: 600;
        height: 100%;
        &:focus {
          border: 1px solid v.$color-secndario-svgs;
          box-shadow: 0 0 2px v.$color-secndario-svgs;
        }
        &::placeholder {
          padding-top: 1rem;
          color: v.$color-letra;
          font-size: v.$font-letra-small;
          font-weight: 600;
        }
      }
    }
  }
  &-id {
    height: 5rem;
    select {
      font-size: v.$font-letra;
      height: 5rem;
      padding: 0 5rem;
    }
  }
}
.Mostrar--bloques{
    padding-left:4.5rem;
    padding-right: 2rem;

}
.filter__idDate {
  &--define {
    height: 5rem;
  }
}
.touchOut {
  z-index: 9;
}
