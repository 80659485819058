@use "../../vars/vars.scss" as v;
@forward "../Score/Header/header.scss";
@forward "../Score/Navigation/navigation.scss";
@forward "../Score/Filters/filter";
@forward "../Score/Session/Session.scss";
@forward "../Score/Items/Items.scss";
@forward "../Score/Footer/footer.scss";
@forward "../Score/Profile/Profile.scss";
@forward "../Score/ModalConfig/modalconfigmobile";
.Score__landscape {
  display: none;
}
.nav__mobile {
  width: 100%;
  position: sticky;
  z-index: -1;
}
.Mobile__intento{
  width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}
.score--container{
  z-index: -2;
}
.score__containerdesk{
  background-color: v.$color-background;
}
@media (max-height: 505px) and (orientation: landscape) {
  .Mobile__intento {
    display: none;
  }

  .Score__landscape {
    display: flex;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
    font-size: v.$font-letra;
    flex-direction: column;
    color: v.$color-letra;
    img {
      width: 5rem;
    }
  }
}
  