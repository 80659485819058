@use "../vars/vars.scss" as v;

.modalmapa{
   width: 100%;
   height: 100vh;
   position: absolute;
   background-color: rgba(0, 0, 0, 0.103);
   z-index: 9;
   display: flex;
   top: 0;
   left: 0;
   align-items: center;

}
.modalmapa--close{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
}
.modalmapa__mapa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    align-content: center;
    width: 50%;
    background-color: white;
    border-radius: 1rem;
    
    height: 60%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}
.modalmapa__mapa--container{
    width: 95%;
    height: 100%;

}
.modalmapa__mapa--closex{
    background-color: v.$color-secndario-svgs;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 1rem 1rem 0rem  0rem;

    height: 8%;
}
.modalmapa__mapa--closex button{
    width: 7rem;
    height: 93%;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 4rem;
    cursor: pointer;
}
.modalmapa__mapa--closex h3{
    color: white;
    font-size: 2.5rem;
    margin-left: 1rem;
}
.modalmapa__mapa--done{
    width: 100%;
    height: 8%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0rem 0rem 1rem 1rem;

}
.modalmapa__mapa--done button{
    width: 10rem;
    height: 70%;
    font-size: 2rem;
    color: white;
    border: none;
    border-radius: 1rem;
    margin-right: 1rem;
    background-color: v.$color-secndario-svgs;
    cursor: pointer;


}
.modalmapa--marker{
    width: auto;
   
}
.modalmapa--marker path{
    fill:  v.$color-secndario-svgs;
}
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 90px;
    background-color: v.$color-secndario-svgs;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 118%;
    margin-left: -57px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  @media (max-width:1025px) {
    .tooltiptext  {
    display: none;
    }
  }