.expediente{
    height: 100%;
  display: flex;
    overflow-y: scroll;
    &--cajita{
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;

    }
    &--documentos{
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        &--img{
            margin-bottom: 1rem;
        }
    }
    &--tabla{
        padding: 0 7%;
    }
}.expediente--mapa {
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    padding: 0 7%;
    padding-top: 2%;

  }.expediente--tablas {
    width: 100%;
    max-width: 100%;
  }
  .proceedings--map {
    min-height: 35rem;
  }
  .expediente--documentos--img{
    max-width: 400px;
  }