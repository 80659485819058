@use "../../../vars/vars.scss" as v;
.score {
    height: 105%;
}
.score--header {
    width: 33.33%;
    height: 5rem;

    display: flex;
    justify-content: flex-start;
    &--images {
        display: none;
        justify-content: flex-end;
    }
    p {
        display: flex;
        width: 70%;
        text-align: left;
        font-size: v.$font-letra;
        color: v.$color-letra;
        padding: 0;
        align-items: center;
        br {
            display: none;
        }
    }
    &--logo {
        width: 15%;
        padding: 1rem;
        height: 100%;
    }
    &--buscar {
        width: 33.33%;
        height: 5rem;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
        &--search {
            width: 2.5rem;
            position: relative;
            right: 4rem;
        }
        &--user {
            cursor: pointer;
            width: 4rem;
        }
        input {
            border: 1.2px solid #acadad;
            border-radius: 0.8rem;
            padding-left: 1.2rem;
            font-size: v.$font-letra;
            color: v.$color-letra;
            width: 40.33%;
            max-width: 20rem;
            height: 80%;
            &:focus {
                border: 1px solid v.$color-botones;
            }
        }
    }
}
.Header--desktop {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid v.$color-bordes;
    padding: 0.1rem 5%;
}

.score {
    &__svg {
        svg {
            width: 3.4rem;
            height: 100%;
            g {
                fill: v.$color-secndario-svgs;
            }
        }
        &__search {
            width: 100%;
            height: 100%;
            svg {
                width: 3.4rem;
                height: 100%;
            }
            g {
                fill: v.$color-secndario-svgs;
            }
        }
    }
}
