@use "../../../vars/vars.scss" as v;

.filter {
  width: 40%;
  max-width: 402px;
  display: flex;
  padding: 0;
  z-index: 0;
  justify-content: center;
  align-items: flex-end;

  &--date {
    margin-bottom: 0;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  &--box {
    height: 100%;
    padding: 2.25% 0;
    cursor: pointer;
    &--definir {
      display: none;
    }
  }
  &--calendary {
    top: 10rem;
  }
  &-id {
    display: none;
    height: 4rem;
    select {
      font-size: v.$font-letra;
      height: 4rem;
      padding: 0 5rem;
    }
  }
}

.Mostrar--bloques {
  padding-left: 0;
  padding-right: 0;
}
.filter--desktop {
  display: inline-flex;
  width: 100%;
  padding-right: 1.2rem;
  min-height: 5rem;
  border-bottom: 1px solid v.$color-bordes;
  justify-content: center;
}
