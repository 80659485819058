@use "../../../vars/vars.scss" as v;

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 10%;
  max-height: 3%;
  position: sticky;
  z-index: unset;
  min-height: 4.5rem;
  bottom: 0;
  box-shadow: 0px 4px 10px 0px black;
  &--enpaginado {
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 100%;
    margin-left: 35%;
    font-size: v.$font-letra;
    &--svg {
      width: 40%;
      height: 100%;
      fill: v.$color-secndario-svgs;
    }
    button {
      background-color: transparent;
      border: none;
      height: 4rem;
    }
    &--numeros {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 50%;
      height: 100%;
      span {
        color: v.$color-secndario-svgs;
        font-size: v.$font-letra;
        margin: 0 1rem;
        font-weight: 600;
      }

      &--izquierda {
        color: v.$color-secndario-svgs;
        font-size: 2rem;
      }
      &--derecha {
        color: v.$color-letra;
        font-size: 2rem;
      }
    }
  }

  figure {
    display: none;
    width: 10%;
    height: 100%;
    background: none;
    border-radius: 1rem;

    img {
      width: 90%;
      height: 100%;
    }
  }
  &--resultados {
    border: none;
    color: v.$color-letra;
    background-color: transparent;
    border-radius: 0.6rem;
    margin-left: 2rem;
    width: 50%;
    height: 100%;
    img {
      width: 38%;
      height: 100%;
    }
    p {
      width: 70%;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  &__modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    svg {
      border: 1px solid v.$color-bordes;
      path {
        fill: v.$color-secndario-svgs;
      }
    }
    h1 {
      font-size: v.$font-letra-big;
      color: v.$color-botones;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1.7rem;
    }
    ul {
      width: 100%;
      li {
        padding: 2rem 0;
        list-style: none;
        color: v.$color-letra;
        font-size: v.$font-letra;
        font-size: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          background-color: v.$color-secndario;
        }
      }
    }
    &--modal{
        width: 100%;
        border-top: 1px solid v.$color-bordes;
        padding: 0 5%;
    
        padding-top: 8%;
        height: 60rem;
    }
    &--close {
      width: 95%;
      display: flex;

      justify-content: center;
      button {
        width: 50%;
        max-width: 70rem;
        color: v.$color-letra;
        font-size: v.$font-letra;
        background-color: transparent;
        color: none;
        border: none;
        height: 3.5rem;
        margin: 0 auto;
      }
    }
  }
  &--active {
    z-index: -1;
    visibility: hidden;
  }
}
.footer__closemodal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

}
