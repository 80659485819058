@use "../../../../vars/vars.scss" as v;

.aml{
    width: 100%;
    height: 100%;
}
.aml--nodata{
    font-size: 2.5rem;
    text-align: center;
    margin-top: 4rem;
    color: v.$color-letra;
}