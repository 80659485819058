@media (min-width: 576px) {
    .facematch {
        &--aceptabilidad {
            &--semejanza {
                p {
                    height: 30%;
                }
                h5 {
                    font-size: 2.4rem;
                }
            }
            &--coincidencia {
                width: 50%;
                p {
                    height: 30%;
                    margin: 0 auto;
                }
            }
        }
        &--imagenes {
            margin-top: 1.5rem;
            img {
                width: 22%;
                height: 30%;
            }
        }
    }

    .Ine {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        &--button {
            width: 50%;
            height: 10%;
            margin-top: 4rem;
            svg {
                width: 12.5%;
            }
        }
        &--image {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            &--img {
                margin-top: 5rem;
                width: 49%;
                margin-right: 1%;
                height: auto;
            }
        }
    }
}
