@use "../../../vars/vars.scss" as v;

.Mostrar {
    display: flex;
    width: 50%;
    max-width: 560px;
    height: 100%;
    padding: 0.4rem 0;
    &--fecha p{
        color: v.$color-letra;
    }
    &--bloques{
        margin: 0 1.5rem;
    }
    .rmdp-container{
        margin: 0 1rem;
    }
    &__caja {
        display: flex;
        width: 33%;
        align-items: center;
        justify-content: center;
        height: 95%;
        min-height: 3.8rem;
        padding-left: 1%;
        margin-right: 0.2rem;
        background-color: v.$color-claro;
        border-radius: 1rem;

        border: 1px solid v.$color-bordes;
        p {
            color: v.$color-letra;
            font-size: v.$font-letra;
            cursor: pointer;
            width: 91%;
            padding-left: 0.1rem;
        }
        &--svg{
            path{
            }
        }
      
    }
    &--calendary{
        width: 100%;
        height: 95%;
        min-height: 3.8rem;
    }
    &--results{
        p{
            text-align: center;
        }
    }
    
    
    body {
        z-index: 1;
    }
    &__date {
        width: 100%;
        z-index: 3;
        height: 100%;
        top: 0;
        position: absolute;
        margin: 0 auto;
        right: 0%;
        div {
            margin: 0 auto;
            width: 90%;
            max-width: 73rem;
            height: 50%;
            margin-top: 2.4rem;
            z-index: 2;
            input {
                color: v.$color-letra;
                font-size: 1.4rem;
                font-weight: 600;
                width: 150%;
                height: 5rem;
                z-index: 4;
                &:focus {
                    border: 1px solid v.$color-secndario-svgs;
                    box-shadow: 0 0 2px v.$color-secndario-svgs;
                }
                &::placeholder {
                    padding-top: 1rem;
                    color: v.$color-letra;
                    font-size: 1.4rem;
                    font-weight: 600;
                }
            }
            span {
                margin-right: 5%;
                margin-top: 1.5rem;
                z-index: 5;
            }
        }
    }
    &__show {
        position: relative;
        width: 0%;
        z-index: 6;
        height: 25%;
        right: 0;
        &--container {
            display: flex;
            flex-direction: column;
            background-color: v.$color-claro;
            width: 15rem;
            height: 15rem;
            border: 2px solid v.$color-bordes;
            border-radius: 0.9rem;
            position: absolute;
            right: -1%;
            top: 5rem;
            text-align: center;
            z-index: 4;
            h1 {
                font-size: v.$font-letra;
                color: v.$color-letra;
                text-align: center;
            }
            ul {
                width: 100%;
                li {
                    padding-top: 1rem;
                    border-bottom: 1px solid v.$color-bordes;
                    list-style: none;
                    cursor: pointer;
                    color: v.$color-letra;
                    font-size: v.$font-letra;
                    &:hover {
                        background-color: v.$color-botones;
                        color: v.$color-botones-text;
                    }
                }
            }
        }
    }
    &__id {
        display: flex;
        flex-direction: column;
        background-color: v.$color-claro;
        width: 20%;
        max-width: 20rem;
        border: 2px solid v.$color-bordes;
        border-radius: 0.9rem;
        position: absolute;
        top: 10rem;
        z-index: 6;
        text-align: center;
        margin-left: 22rem;
        h1 {
            font-size: v.$font-letra;
            color: v.$color-letra;
            text-align: center;
        }
        ul {
            width: 100%;
            height: 100%;
            li {
                padding-top: 0.2rem;
                border-bottom: 1px solid v.$color-bordes;
                list-style: none;
                cursor: pointer;
                height: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: v.$color-letra;
                font-size: v.$font-letra;
                &:hover {
                    background-color: v.$color-botones;
                    color: v.$color-botones-text;
                }
            }
        }
        &--fecha{
            width: 15rem;
        }
    }
}
.Mostrar__closemodals{
    width: 100vw;
    height: 100vh;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
}
.rmdp-container{
    width: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
    height: 95% !important;
    margin-right: 0.2rem;
    
}

.rmdp-container > div {
    height: 100%;
    max-height: 70vh;
}