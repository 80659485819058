@use "../../../vars/vars.scss" as v;
.score--container--items {
    height: 100%;
}
.Score__landscape{
    display: none;
}
.score--container{
    padding-bottom: 0;
    z-index: -5;
}
.items {
    height: 9rem;
    max-height: 10rem;
    position: sticky;
    z-index: -10;
    div {
        p {
            font-size: v.$font-letra;
        }
    }
    &__skeleton {
        padding: 0.3rem;
        width: 100%;
        height: 7rem;
        background-color: white;
        display: flex;
        &--button {
            padding: 0.2rem;
        }
        &--date {
            padding: 0.2rem;
            width: 30%;
        }
        &--info {
            padding: 0.2rem;
            width: 60%;
        }
    }
}
.items__nodata{
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    height: 100%;
    font-size: v.$font-letra;
    text-align: center;
    color: v.$color-letra;
}
.score__container {
    width: 100%;
    margin-bottom: 34rem;
}
.Mobile__intento {
    width: 100%;
    height:100%;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


@media (max-height: 505px) and (orientation: landscape) {
    .Mobile__intento{
        display: none;
    }
    .Score__landscape{
        display: flex;
    }
 }

@media (width: v.$large-width) {
  
    .div {
        .items {
            width: 19%;
            &__pdf {
                width: 20%;
            }
            a {
                pointer-events: none;
            }
        }
    }
}
