@use "../../../vars/vars.scss" as v;

.profile {
    display: block;
    height: 99vh;
    margin: 0 auto;
    padding-top: 0.5rem;
    width: 100%;
    background-color: v.$color-background;
    box-shadow: #acadad 0px 0px 9px 1px;
    position: absolute;
    z-index: 13;
    top: 0;
    margin: 0 auto;
    overflow: hidden;
    &--button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
            background: none;
            border: none;
            width: 10%;
            height: 10%;
            &:hover {
                background: whitesmoke;
            }
            svg {
                width: 100%;
                height: 100%;
                fill: red;

                path{
                    stroke: v.$color-secndario-svgs;
                }
            }
        }
    }
    &--header {
        width: 100%;
        height: 11%;
        min-height: 10rem;
        display: inline-flex;
        overflow-y: hidden;
        justify-content: space-around;
        &--buttons{
            width: 10%;
            max-width: 10rem;
            height: 7.3rem;
            display: flex;
            align-items: center;
            figure{
                width: 5rem;
                svg{
                    width: 85%;
                    max-width:3.5rem;
                }
            }
        }
        &--datos {
            width: 80%;
            max-width: 24rem;
            height: 8rem;
            padding-left: 0.5rem;
        }
        &--image {
            width: 20%;
            height: 7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0;
            img {
                width: 86%;
            }
            div{
                width: 100%;  
                height: 70%;
                span{
                    height: 70%;
                }
            }
        }
        h1 {
            color: v.$color-letra;
            font-size: v.$font-letra;

        }
        p {
            font-size: v.$font-letra;
            color: v.$color-letra;
        }
    }
    &--options {
        width: 99%;
        margin: 0 auto;
        height: 85%;
        margin-top: 1rem;
        &--buttons {
            display: inline-flex;
            width: 100%;
            height: 8%;
            max-height: 4rem;
            button {
                background: none;
                width: 24.7%;
                max-height: 6rem;
                background-color: whitesmoke;
                border: none;
                border-radius: 0.5rem 0.5rem 0 0 ;
                border-bottom: 1px solid gainsboro;
                color: v.$color-letra;
                font-size: 1.3rem;
                cursor: pointer;
                &:focus {
                    background: v.$color-botones;
                    color: v.$color-botones-text;
                    border: none;
                }
            }
        }
    }
    .active {
        background: v.$color-botones;
        color: v.$color-botones-text;
        border: none;
    }
    &--info {
        overflow: auto;
        height: 94%;
        padding-bottom:25%;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 1%;
        flex-direction: column;
        background-color: v.$color-claro;
        overflow-y: scroll;
        
    }
    
}
.cajita {
    border-radius: 0.2rem;
    width: 100%;
    height: auto;
    color: v.$color-letra;
    background-color: whitesmoke;
    margin-bottom: 1rem;
    &--titulos {
        display: inline-flex;
        width: 100%;
        align-items: center;
        h1 {
            width: 90%;
            padding: 1rem 0.5rem;
            padding-top: 1.5rem ;
            font-size: v.$font-letra;
        }
        img {
            width: 10%;
            padding: 0.5rem;
        }
    }
    &--info {
        display: none;
       
    }
    &--active {
        display: block;
        margin-top: 1rem;
        padding: 0;
        background: v.$color-claro;
        &--info {
            width: 100%;

            padding: 0.5rem;
            height: auto;
            font-size: v.$font-letra;
            background-color: ghostwhite;
            p{
                padding-left: 1rem;
            }
        }
    }
    .box--svg{
        width: 10%;
        height:100%;
        path{
            fill: v.$color-botones;
        }
    }
}

.recibo{
    width: 100%
}
@media (max-height: 505px) and (orientation: landscape) {
   .profile{
       display: none
   }
}


@import "./Score/Score--score";
@import "../Profile/Expediente/expediente";
@import "../Profile/Identidad/Facematch";
@import "../Profile/Aml/Aml.scss";


