@use "../../../styles/vars/vars.scss" as v;
.config{
  background-color: v.$color-background;
  }
.cajatemas {
  display: flex;
  width: 99%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  overflow-x: hidden;
  text-align: center;
  background-color: v.$color-background;
  &__containerpicker {
    display: flex;
    justify-content: flex-end;
  }
  &__picker {
    width: 20rem;
    height: 40rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &--input {
      display: flex;
      align-items: center;
    background-color: white;
      
      padding: 0.2rem;
      position: relative;
      z-index: 1;
      button {
        border: none;
        border-radius: 1rem 0 0 1rem;
        width: 3rem;
        height: 3rem;

        margin-right: 0.5rem;
      }
      input {
        width: 16rem;
        height: 3rem;
      }
    }
  }
  &__header {
    width: 100%;
    height: 5rem;
    display: flex;
    border-bottom: 1px solid v.$color-bordes;
    align-items: center;
    background-color: v.$color-background;
    &--button {
      border: none;
      background-color: v.$color-background;
      width: 17%;
      height: 100%;
    }
    a {
      width: 20%;
      height: 100%;
    }
    svg {
      width: 100%;
      height: 100%;
      fill: v.$color-secndario-svgs;
      path {
        fill: v.$color-secndario-svgs;
      }
    }
    h4 {
      padding-top: 0.3rem;
      font-size: v.$font-letra;
      color: v.$color-letra;
    }
  }
  &__main {
    height: 40rem;
    margin: 0 auto;
    width: 100%;
    margin-top: 1rem;
    max-width: 1024px;
    z-index: 1;
    background-color: v.$color-background;
  }
  &--contenido {
    display: flex;
    width: 100%;
    height: 5rem;
    align-items: center;
    justify-content: space-evenly;

    p {
      font-size: v.$font-letra;
      padding-left: 1rem;
      width: 80%;
      text-align: left;
      color: v.$color-letra;
    }
    svg {
      height: 3rem;
      width: 100%;
      cursor: crosshair;
    }
  }
  &--caja {
    width: 100%;
    height: 6rem;
  }
  &__buttons {
    height: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &--aply {
      cursor: pointer;
      border: none;
      height: 80%;
      width: 18%;
      background-color: v.$color-botones;
      color: v.$color-botones-text;
      border-radius: 0.5rem;
      min-width: 16rem;
    }
    &--reset {
      cursor: pointer;
      border: none;
      height: 80%;
      width: 18%;
      min-width: 16rem;
      background-color: v.$color-botones;
      color: v.$color-botones-text;
      border-radius: 0.5rem;
      margin-right: 1rem;
    }
  }
  &__buttons button:hover {
    opacity: 0.5;
  }
  &__svg {
    &--botones {
      background-color: v.$color-bordes;
      g {
        fill: v.$color-botones;
      }
    }
    &--botonesLetras {
      background-color: v.$color-bordes;
      g {
        fill: v.$color-botones-text;
      }
    }
    &--letra {
      background-color: v.$color-bordes;

      g {
        fill: v.$color-letra;
      }
    }
    &--iconos {
      background-color: v.$color-bordes;
      g {
        fill: v.$color-secndario-svgs;
      }
    }
    &--background {
      background-color: v.$color-bordes;
      g {
        fill: v.$color-background;
      }
    }
  }
}

.react-colorful {
  position: absolute;
  left: 0;
  z-index: 4;
  height: 100%;
  width: 37%;
}
.picker--default {
  width: 100%;
  display: flex;
  height: 10rem;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border: 1px solid v.$color-bordes;
  flex-wrap: wrap;
  button {
    width: 4rem;
    border: none;
    height: 3rem;
  }
}

.config {
  height: 100vh;
  &__header {
    display: flex;
    height: 7rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid v.$color-bordes;
    background-color: v.$color-background;

    div {
      width: 100%;
      height: 100%;
      padding: 0 4%;
      display: flex;
      align-items: center;
      align-content: center;
    }
    &--space {
      display: none;
    }
    &--title {
      color: v.$color-letra;
      font-size: 2rem;
      font-weight: 600;
    }
    &--button {
      button {
        width: auto;
        background: none;
        border: none;
        svg {
          width: 10rem;
          cursor: pointer;
          height: 5rem;
          path {
            fill: v.$color-secndario-svgs;
          }
        }
      }
    }
  }
}
.boxtheme__drops {
  max-width: 3rem;
  border: 1px solid v.$color-bordes;
}
.configoptions {
  &--svg {
    width: 4.5rem;
    margin-left: 0.5rem;
    height: 4rem;
    
    border-radius: 3rem;
    margin-right: 2rem;
    path{
      fill: v.$color-secndario-svgs;
    }
  }
  &--title {
    height: 5rem;
    display: flex;
    align-items: center;
    padding-left: 5%;
    color: v.$color-letra;
    font-size: 1.5rem;
    border-bottom: 1px solid v.$color-bordes;
  }
  &__option {
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    height: 7rem;
    background-color: v.$color-background;

    p {
      font-size: 1.3rem;
      color: v.$color-letra;
      font-weight: 600;
    }
  }
  &__option:hover path {
    fill: v.$color-secndario;
  }
  &__option:hover p {
    color: v.$color-secndario-svgs;
  }
}
.config__container {
  display: flex;
}
.config__options {
  width: 32%;
  max-width: 350px;
  border: 1px solid v.$color-bordes;
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.config__modal {
  height: 91vh;
  
  width: 100%;
}
.cajatemas__header {
  display: none;
}

/* 
  cursor pinter en banderas modal 
  muy pronto te contactaremos 
  instagram y facebook debajo de los email 
  icono en lila y fondo en blanco 
  header close
  y el registro-BO 
*/
