@use "../../../vars/vars.scss" as v;
.session {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    height: 5rem;
    padding: 0;
    border-bottom: none;
    &__box {
        width: 100%;
        margin-bottom: 0;

        &--titulo {
            width: 100%;
            font-size: v.$font-letra;
            color: v.$color-letra;
            text-align: center;
        }
        &--contenido {
            width: 100%;
            text-align: center;
            color: v.$color-oscuro;
            font-size: v.$font-letra;
        }
    }

    &__input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--checkbox {
            height: 50%;
            width: 53%;
            display: inline-flex;
            padding-left: 1rem;
            p{
                font-size: v.$font-letra;
            }
            input {
                margin: auto;
                width: 100%;
                min-width: 1.5rem;
                min-height: 1rem;
            }
        }
    }
    &--desktop{
        height: auto;
        width: 100%;
        border-bottom: 1px solid v.$color-bordes;
    }
}
.session__filters{
    width: 150%;
    display: flex;
    justify-content: space-between;
    height: auto;
    position: static;
    z-index: 0;
    padding: 0;
    top: 0;
    border: none;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    justify-content: space-between;
    .session__box{
        width: 100%;
        margin-bottom: 0;
    }
    .session__input--checkbox{
        p{
            width: 100%;
        }
    }
}
.session__approved{
    margin-bottom: 0;
}