@use "../../../../vars/vars.scss" as v;
@media (min-width: 992px) {
  .facematch {
    display: flex;
    flex-direction: column;
    &--aceptabilidad {
      width: 70%;
      margin: 0 auto;
      p {
        font-size: 1.6rem;
      }
      h5 {
        font-size: 1.8rem;
      }
    }
    &--imagenes {
      margin-bottom: 1.2rem;
      img {
        width: auto;
        height: 50%;
        max-height: 20rem;
      }
    }
    &--validacion {
      display: flex;
      justify-content: center;
      padding-left: 0;
      padding: 1rem 0;
      button {
        width: 20rem;
        cursor: pointer;
        max-width: 50%;
        height: 5rem;
        max-height: 4rem;
        background-color: var(--botones);
        font-size: 1.6rem;
        margin: 0 auto;
      }
      button:hover {
        opacity: 0.5;
      }
    }
  }
}
.Ine {
  &--image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &--img {
      margin-top: 0;
      width: 49%;
      height: 100%;
      margin-right: 0;
      
    }
  }
  &--button {
    width: 100%;
    margin-top: 0;
    svg {
      width: 4.6%;
    }
  }
  &--nodata {
    padding: 2rem 0;
  }
}
