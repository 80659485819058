@use "../vars/vars.scss" as v;
.modal__close{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  background-color: rgba(var(--text), 0.2);
}
.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  background-color: rgba($color: v.$color-letra, $alpha: 0.2);
    
  &--content {
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    &--button {
        width: 100%;
        height: 7%;
        max-height: 5rem;
        text-align: right;
        display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      button {
        cursor: pointer;
        width: 4.5rem;
        height: 3rem;
        font-size: v.$font-titulo;
        border-radius: 1rem;
        background-color: v.$color-botones;
        border-radius: 1rem 1rem 0rem 0rem;
        border: none;
        color: v.$color-botones-text;
    }
}
&--image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        max-width: 800px;
        max-height: 80%;

      }
    }
  }
  &--cerrado {
    width: 50%;
    height: 100%;
    display: flex;

    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    img {
      width: 90%;
    }
    &--img {
      position: relative;
      right: -35%;
      top: 4rem;
      width: 3rem;
      z-index: 1;
      height: 3rem;
    }
  }
}
