@use "../../../vars/vars.scss" as v;

.ScoreDesktop {
  width: 100%;
  height: calc(100% - 161px);
  max-width: 1024px;
  margin: 0 auto;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-evenly;
  &--lista {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 0.3rem;
    width: 35%;
    box-shadow: 0 0 9px 1px #acadad;
    height: 100%;
    background-color: white;
    ol {
      border-radius: 0.5rem;
      border: 1px solid v.$color-bordes;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    /* width */
    ol::-webkit-scrollbar {
      width: 0.8rem;
    }
    /* Track */
    ol::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px v.$color-letra;
      border-radius: 10px;
      margin-top: 0px;
    }

    /* Handle */
    ol::-webkit-scrollbar-thumb {
      background: v.$color-botones;
      border-radius: 10px;
    }
    /* Handle on hover */
    ol::-webkit-scrollbar-thumb:hover {
      background: v.$color-letra;
    }
  }
  &--profile {
    box-shadow: #acadad 0px 0px 9px 1px;
    background-color: white;
    width: 60.5%;
    border-radius: 1rem;
    height: 100%;
    /* width */
    ::-webkit-scrollbar {
      width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      margin-top: 0px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: v.$color-botones;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: v.$color-letra;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12%;
    max-height: 7.5rem;
    position: relative;
    z-index: 0;
  }
}
.export {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  button {
    cursor: pointer;
    width: 100%;
    height: 5rem;
    max-height: 4rem;
    background-color: v.$color-botones;
    font-size: 1.6rem;
    color: v.$color-botones-text;
    border-radius: 0.5rem;
    margin-right: 1rem;
    border: none;
    &:hover {
      background-color: v.$color-letra;
    }
  }
}
.export__button{
  width: 70%;
}
.footer {
  box-shadow: none;
  min-height: 5.3rem;
}
.footer--enpaginado {
  width: 100%;
  button {
    width: 20%;
  }
  &--svg {
    cursor: pointer;
    path {
      fill: v.$color-secndario-svgs;
    }
    width: 2rem;
  }

  span {
    color: v.$color-secndario-svgs;
  }
  &--numeros {
    &--izquierda {
      color: v.$color-secndario-svgs;
      font-size: v.$font-letra;
    }
    &--derecha {
      color: v.$color-letra;
      font-size: v.$font-letra;
    }
  }
}

.items {
  height: 6.5rem;
  width: 100%;
  position: inherit;
  z-index: 0;
  &__date {
    width: 30%;
  }
  &__hour {
    width: 30%;
    height: 40%;
  }
  a {
    width: 100%;
  }
  &__pdf {
    display: none;
  }
  &__name {
    width: 65%;
  }
}
/* 
indica tu direccion en el mapa padding izquierda 
tamano original de identidad facematch izquierda 
imagenes estan mal 
Titulo de la caja de INE hecharle ojo
arreglar modal de la imagen 
dia mes ano / edad
expediente 
otros documentos en el expediente 
3 divs en scoring header / filters/ scoring
background de las configs 
grises y si estan activados que sea el color de los highlights 
por default config de colores 
texto 
configs iguales que el google cloud 
 CSV mayus config
cambiar icono del csv config como tablita de excel quitar border radius a los iconos 
texto de condig de colores tiene que ser cambiado a
Color 
texto de config
Exportacion de CSV
 color del calendario 
Selecciona un registro color de los textos 
picker de hexadecimal del texto no se puede copiar 
revisada a los colores pickers 
el numero no esta centrado de la listas
border de configs esta raro 
texto de las opciones en regular 
borders en backgrounds de configs 
diagramacion de scoring y config
lista saber el que esta seleccionado 
footer de las paginas en blanco 
flechitas agarre fondo del background 
lista todo en blanco 
color del footer del desktop que sea el mismo del background
espacios iguales en los filtros 
olvidar lo escrito sobre la lista todo la lista que sea blanca en todo la lista 
el background color debe de ser al padre 
background de ipad 
ipad 0 tooltips
background en ipad esta raro 
animacion del footer en mobile el mostrar resultados 
linea del modal del mostrar agregarla igual que en spotify 

*/