@use "../../../vars/vars.scss" as v;

.filter {
  display: flex;
  height: auto;
  padding: 0.7rem 0;
  flex-direction: column;
  z-index: 3;
  &--date {
    display: flex;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
  }
  &--box {
    width: 100%;
    height: 3.4rem;
    padding-top: 1rem;
    text-align: center;
    border-right: 2px solid v.$color-secndario-svgs;
    font-size: v.$font-letra-small;
    color: v.$color-letra;
    &:hover {
      background-color: v.$color-letra;
      color: v.$color-botones-text;
    }
    // &--definir {
    //     border-top-right-radius: 3px 4px;
    //     border-bottom-right-radius: 3px 4px;
    //     border-right: none;
    // }
    &--hoy {
      border-top-left-radius: 3px 4px;
      border-bottom-left-radius: 3px 4px;
    }
    &--mes {
      border-top-right-radius: 3px 4px;
      border-bottom-right-radius: 3px 4px;
      border-right: none;
    }
  }
  &--calendary {
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 19rem;
    left: 0;
    margin: 0 auto;
    div {
      height: 3.5rem;
      input {
        color: v.$color-letra;
        font-size: 1.4rem;
        font-weight: 600;
        height: 100%;
        &:focus {
          border: 1px solid v.$color-secndario;
          box-shadow: 0 0 2px v.$color-secndario;
        }
        &::placeholder {
          padding-top: 1rem;
          color: v.$color-letra;
          font-size: 1.4rem;
          font-weight: 600;
        }
      }
    }
  }
  &-id {
    width: 50%;
    overflow: hidden;
    border: 1px solid v.$color-bordes;
    position: relative;
    border-left: none;
    height: 4rem;
    display: flex;
    &:after {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid v.$color-secndario;
      position: absolute;
      top: 40%;
      right: 5px;
      content: "";
      z-index: 13;
    }
    select {
      width: 99%;
      border: 0;
      position: relative;
      z-index: 10;
      color: v.$color-letra;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      padding-left: 2.1%;
      font-size: 1.6rem;
      padding-right: 2rem;
      background: none;
    }
    select::-ms-expand {
      display: none;
    }
  }
}
.filter__idDate {
  display: flex;
  align-items: flex-end;
  &--define {
    width: 100%;
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    padding: 0 2.1%;
    justify-content: space-between;
    color: var(--text);
    height: 4rem;
    border: 1px solid #dadce0;
    border-right: none;
    svg {
      height: 100%;
    }
  }
}
.rmdp-calendar-container-mobile{
  position: fixed;
  left: 50% !important;
  width: 0% !important;
  top: 0px  !important;
  will-change: transform !important;
  visibility: visible !important;
  z-index: 100 !important;
}
.rmdp-container {
  width: 50%;
   
}
.rmdp-calendar {
    width: auto !important;

}
.rmdp-range{
    background-color: v.$color-bordes !important;
    box-shadow: 0 0 3px v.$color-bordes !important;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover{
    background-color: v.$color-secndario !important;
    color: v.$color-letra; 
}
.rmdp-day.rmdp-today span{
    background-color: v.$color-secndario !important;
}
.rmdp-week-day{
    color: v.$color-secndario !important;
}
.rmdp-arrow-container:hover{
    background-color: v.$color-secndario !important;
}
.rmdp-arrow{
    border: solid v.$color-letra !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block;
    height: 3px;
    margin-top: 5px;
    padding: 2px;
    width: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.rmdp-action-button {
  color: v.$color-secndario !important;
}
input[type="checkbox"] {
  accent-color: v.$color-secndario;
  color: white;
}
.filter__offmodal{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  width: 100%;
  height: 100%;
  
}

.touchOut {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
}
