@use "../vars/vars.scss" as v;
@import "../components/Config/config-large";


@media (max-width: 765px) {
  @import "../components/Config/_config-mobile.scss";
}

@media screen and (min-width: 765px) and (max-width: 992px) {
  @import "../components/Config/_config--tablet.scss";
}
