@use "../../../../vars/vars.scss" as v;

.Score--score {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin: 0.75rem 0;
  h4 {
    width: 33.333%;
    font-size: 1.3rem;
  }
  p {
    width: 33.333%;
    text-align: center;
    font-size: 1.3rem;
  }
  &--aceptado {
    color: v.$color-accept;
  }
  h5 {
    width: 33.333%;
    font-size: 1.3rem;

    text-align: center;
  }
  &--aceptadoGeneral {
    width: 23.333%;
    background-color: v.$color-secndario;
    color: v.$color-claro;
    height: 3rem;
    padding-top: 0.5rem;
    font-size: v.$font-letra;
    h5 {
      width: 33.333%;
    }
  }
  &--rechazado {
    color: v.$color-peligro;
  }
  &--rechazadoGeneral {
    width: 23.333%;
    background-color: v.$color-peligro;
    color: v.$color-claro;
    height: 3rem;
    padding-top: 0.5rem;
    font-size: v.$font-letra;
    h5 {
      width: 33.333%;
    }
  }
}

.Score--geolocalizacion {
  height: auto;
  width: 100%;
  &--skeletonMapa {
    height: 30rem;
    width: auto;
    margin-left: 3rem;
  }
  &--skeletonImage {
    height: 30rem;
    margin-left: 3rem;
    width: 100%;
  }
  &--mapa {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    img {
      width: 70%;
      height: 20%;
    }
  }
  &--data {
    width: 100%;
    display: inline-flex;
    margin: 1.5rem 0;
    h4 {
      width: 10rem;
    }
  }
  &--image {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    img {
      margin: 1rem;
      border: 1px solid v.$color-bordes;
      width: 49%;
      min-width: 14rem;
      min-height: 16rem;
      margin: 0.5% 0.5%;
    }
  }
}
.score__geolocalizacion--googlemaps {
  width: 80%;
  height: 30rem;
  margin-bottom: 2rem;
}
.profile--score,
.profile--identidad {
  width: 100%;
}
@media (min-width: 576px) {
  .Score--geolocalizacion {
    &--skeletonMapa {
      height: 30rem;
      width: auto;
      margin-left: 3rem;
    }
    &--skeletonImage {
      height: 30rem;
      margin-left: 3rem;
      width: 100%;
    }

    &--mapa {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      img {
        width: 60%;
      }
    }
    &--image {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      img {
        margin: 1rem;
        border: 1px solid v.$color-bordes;
        width: 49%;
        min-width: 14rem;
        min-height: 20rem;
        margin: 0.5% 0.5%;
        max-width: 20rem;
      }
    }
  }
  .Score--score {
    h5 {
      font-size: 1.6rem;
    }
  }
}
@media (min-width: 992px) {
  .profile--score,
  .profile--identidad {
    padding-bottom: 32%;
  }
  .Score--score {
    h4 {
      width: 33.333%;
      font-size: 1.6rem;
    }
  }
  .Score--geolocalizacion {
    &--skeletonMapa {
      padding-left: 10%;
      height: 30rem;
    }
    &--skeletonImage {
      height: 40rem;
      width: 100%;
      padding-left: 10%;
    }
    &--mapa {
      text-align: center;
      img {
        width: 60%;
      }
    }
    &--image {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      margin: 4% auto;
      img {
        border: 1px solid v.$color-bordes;
        width: 25%;
        min-width: 17rem;
        min-height: 15rem;
        margin: 0.5%;
      }
    }
  }
}
