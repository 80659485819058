@use "../../../vars/vars.scss" as v;
.session {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    height: 5rem;
    padding: 0;
    border-bottom: 1px solid v.$color-bordes;
    &__box {
        width: 85%;

        &--titulo {
            width: 100%;
            padding-left: 0.3rem;
            font-size: v.$font-letra;
            color: v.$color-letra;
            text-align: center;
        }
        &--contenido {
            width: 100%;
            text-align: center;
            color: v.$color-oscuro;
            font-size: v.$font-letra;
        }
    }
    &__input {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--checkbox {
            width: 78%;
            height: 50%;
            display: inline-flex;
            input {
                margin: auto;
            }
        }
    }
}
.session__filters{
    width: 150%;
    display: flex;
    justify-content: space-between;
    height: auto;
    position: static;
    z-index: 10;
    padding: 0;
    top: 0;
    border: none;
    display: flex;
    flex-direction: row;
    background-color: none;
    justify-content: space-between;
    .session__box{
        width: 100%;
        margin-bottom: 0;
    }
}
.session__outelement{
    display: none;
}