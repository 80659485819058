@use "../../../../styles/vars/vars.scss" as v;

.csvadvanced__custom{
    display: flex;
    width:40%;
    padding: 2% 0;
    flex-direction: row;
    flex-direction: column;
    &--input{
        height: 4rem;
        width: 100%;
        background-color: #00000034;
        border: none;
        border-radius: 0.7rem;
        padding-left: 1rem;
        color: v.$color-letra;
        margin-bottom: 1rem;
    }
  
}
.csvadvanced{
    width: 100%;
    margin-left: 0rem;
    &__submit{
        width: 83%;
        max-width: 1080px;
        display: flex;
        justify-content: flex-end;
        &--button{
            border: none;
            width: 19rem;
            height: 4.5rem;
            background-color: v.$color-botones;
            color: v.$color-claro;
            border-radius: 1rem;
            &:hover{
                opacity: 0.5;
            }
        }
    }
    &__form{
        display: flex;
        flex-direction: column;
        align-items: center;
        &--checkbox{
            width: 5%;
        }
        &--custom{
            width: 40%;
        }
        &--default{
            width: 40%;
            margin-right: 1rem;

        }
    }
}
.csvadvanced__checkbox{
    display: flex;
    width: 12%;
    padding: 2% 1%;
    flex-direction: row;
    flex-direction: column;
    &--input{
        height: 2.9rem;
        margin: 1.05rem;
        width: auto;
        background-color: #00000034;
        border: none;
        border-radius: 0.7rem;
        padding-left: 1rem;
        color: var(--text);
    }
}
.csvadvanced__inputs{
    display: flex;
    align-items: center;
    height: 10%;
    flex-direction: column;
  

}
.csvadvanced__default{
    width: 33%;
    display: flex;
    padding: 2%;
    flex-direction: column;
    input{
        height: 4rem;
        width: 100%;
        background-color: #00000034;
        border: none;
        border-radius: 0.7rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
        color: var(--text);
    }

}
.csvadvanced__inputs{
    display: flex;
    width: 100%;
    max-width: 1300px;
    flex-direction: row;
    justify-content: flex-start;
}
.csvadvanced__index{
    width: 6%;
    display: flex;
    padding: 2% 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input{
        height: 4rem;
        width: 100%;
        text-align: center;
        background-color: #00000034;
        border: none;
        border-radius: 0.7rem;
        margin-bottom: 1rem;
        color: var(--text);
    }
}
.csvadvanced__titles{
    text-align: center;
    width: 100%;
    color: v.$color-botones;
    padding: 1rem 0;
    font-size: 1.7rem;
}
