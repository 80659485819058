@use "../../../vars/vars.scss" as v;
.navigation{
    display: flex;
    height:4.4rem;
    position: sticky;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid v.$color-bordes;
    margin-top: 0.5rem;
    z-index: -1;
    &--box{
        width: 33.33%;
        height: 100%;
        font-size: v.$font-letra-small;
        color: v.$color-letra;
        text-align: center;
        padding-top: 0.2rem;
        background-color: transparent;
        border: none
    }
}
.active{
    border-bottom: 4px solid v.$color-botones;
}

@media (min-width:576px) {
    .navigation{
        border-bottom: 1px solid v.$color-bordes;
        height: 4rem;
        &--box{
            font-size: v.$font-letra-small;
            
        }
    }
}
@media (min-width:992px) {
    .navigation{
        height: 5rem;
        width: 33.33%;
        margin-top: 0;
        justify-content: space-evenly;
        border-bottom:none;
        &--box{
            font-size: v.$font-letra;
            width: 40%;
            max-width: 20rem;
            height: 107%;
        }
    }
}