@use "../../../vars/vars.scss" as v;
.profile {
    height: 99.4vh;
    box-shadow: #acadad 0px 0px 9px 1px;
    &--button {
        button {
            width: 7%;
            height: 5%;
        }
    }
    &--header {
        width: 100%;
        height: 12rem;
        padding: 0 3rem;
        &--datos {
            width: 100%;

            h1 {
                font-size: v.$font-titulo;
            }
            p {
                font-size: v.$font-letra;
            }
        }
        &--image {
            width: 20%;
            height: 10rem;

            div {
                height: 70%;
            }
            img{
                width: 70%;
            }
          
        }
        &--buttons{
            height: 8.7rem;
        }
    }
    &--options {
        height: 100%;
        &--buttons {
            width: 100%;
            height: 6rem;
            button {
                width: 50%;
                font-size: v.$font-letra;
            }
        }
    }
    &--info{
        height: 95%;
        padding-bottom:30%;

        padding-top: 1%;
    }
    
}
.cajita {
    background-color: whitesmoke;

    &--titulos {
        display: inline-flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        h1 {
            font-size: v.$font-letra;
            padding: 2rem 2.2rem;
        }
        img {
            width: 6%;
        }
    }
    &--active {
        &--info {
            padding:0  5.5%;            
            p {
                font-size: v.$font-letra;
            }
        }
    }
}
.cajita .box--svg{
    width: 6%;
}