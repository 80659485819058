@use '../../vars/vars.scss' as v;
@media (min-width:v.$medium-width) {
    @import "../Score/Header/header-medium";
    @import "../Score/Filters/filter-medium";
    @import "../Score/Session/session-medium";
    @import "../Score/Items/items-medium";
    @import "../Score/Footer/footer-medium";
    @import "../Score/Profile/Profile-medium";
    @import "../Score/ModalConfig/modalconfigtablet";

}
