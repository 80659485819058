@use "../../../vars/vars.scss" as v;
.profile {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: v.$color-claro;
  box-shadow: none;
  position: inherit;
  padding-bottom: 0;
  &--button {
    display: none;
  }
  &--header {
    height: 25%;
    max-height: 15rem;
    align-items: center;
    &--datos {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12rem;
      img {
        width: 75%;
        height: auto;
        border-radius: 2rem;
      }
      div {
        width: 88%;
        height: 8rem;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
    &--buttons {
      width: 40%;
      display: inline-flex;
      height: 10rem;
      justify-content: flex-end;
      figure {
        margin-right: 5%;
        margin-bottom: 1rem;
        width: 10.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 5rem;
          height: 5rem;
        }
        a {
          cursor: pointer;
        }
      }
    }
  }
  &--options {
    height: 100%;
    margin-top: 0;
    &--buttons {
      height: 100%;
      max-height: 4.5rem;
      button {
        max-height: 5.5rem;
        border-radius: 0.7rem 0.7rem 0 0;
        font-size: 1.6rem;
      }
    }
  }
  &--info {
    padding-top: 0;
    height:100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 0%;
    
  }
}
.cajita {
  margin-top: 0.5rem;
  height: auto;
  margin-bottom: 0;
  &--info{
    height: auto;
    position: sticky;
  }
  &--titulos {
    h1 {
      width: 95%;
    }
    svg {
      path {
        cursor: pointer;
      }
    }
  }
}
.img--active {
  padding: 0 0.8rem;
}
.score__pickresult {
  color: #6c757d;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
}
.cajita--titulos {
  h1 {
    padding: 2rem 2rem;
  }
}
