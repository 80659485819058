@use "../../../vars/vars.scss" as v;

.score {
    &--header {
        height: 6rem;
        p {
            width: 60%;
            font-size: v.$font-letra;
            color: v.$color-letra;
            padding-right: 5%;
        }

        &--images {
            width: 15%;
            justify-content: space-around;
            padding: 1rem;
        }
        &--input {
            right: 13%;
            top: 1.5%;

            input{
                font-size: v.$font-letra;
            }
        }
        &--buscar{
            input{
                font-size: v.$font-letra;
            }
        }
    }
    &__search {
        margin-left: 0;
        svg {
            width: 2.7rem;
        }
    }
    &__svg {
        svg {
            width: 2.2rem;
        }
    }
}
.Mobile__intento {
    width: 100%;
    height: 93.6vh;
}
