*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}
:root{
    --secundario: #80B51C;
    --secundario-svg: #80B51C;
    --botones: #80B51C;
    --botones--text: #ffffff;
    --background--color: #ffffff;
    --text: #6c757d;
}

html{
    font-size: 62.5%;
    font-family: sans-serif;
    height: 100%;
}
$font-letra: 1.6rem;
$font-letra-small: 1.3rem;
$font-letra-big: 1.8rem;
$font-titulo: 2rem;

$color-secndario: #80B51C ;
$color-secndario-svgs: var(--secundario-svg);
$color-letra: var(--text);
$color-botones:var(--botones); 
$color-botones-text:var(--botones--text); 
$color-background:var(--background--color); 

$color-peligro: #ff5252;
$color-oscuro: black;
$color-claro: white;
$color-bordes: #dadce0;
$color-moyo: #80B51C;
$color-notactive:#AAA;
$color-accept: #80B51C;
$color-svgs:5f6368;

$small-width: 576px;
$medium-width: 576px;
$large-width: 992px;
