@use "../../../../vars/vars.scss" as v;

.facematch {
    &--aceptabilidad {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;

        &--coincidencia {
            width: 50%;
            background-color: v.$color-claro;

            p {
                border: 1px solid v.$color-letra;
                text-align: center;
                background-color: v.$color-claro;
            }

            img {
                width: 20%;
                margin-left: 40.5%;
            }
        }
        &--semejanza {
            background-color: v.$color-claro;

            width: 50%;
            p {
                border: 1px solid v.$color-letra;
                border-left: none;
                text-align: center;
                background-color: v.$color-claro;
            }
            h5 {
                width: 100%;
                font-size: v.$font-letra;
                padding-top: 5%;
                font-weight: 400;
                text-align: center;
            }
        }
    }
    p {
        width: 100%;
        text-align: center;
    }
    &--imagenes {
        display: inline-flex;
        width: 100%;
        justify-content: space-evenly;
        img {
            width: 40%;
            max-width: 15rem;
            height: 60%;
        }
    }
    &--validacion {
        button {
            background-color: v.$color-botones;
            width: 50%;
            height: 4rem;
            margin-left: 25%;
            border: none;
            color: v.$color-botones-text;
            border-radius: 0.5rem;
        }
    }
}

.Ine {
    width: 100%;
    height: 100rem;
    &--button {
        width: 95%;
        background: none;
        border: none;
        text-align: right;
        svg {
            width: 8.5%;
            cursor: pointer;
            path {
                fill: black;
            }
        }
        div {
            overflow-y: scroll;
        }
    }
    &--image {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &--img {
            width: 80%;
            height: 50%;
            margin-bottom: 1rem;
        }
    }
}
.Validacion {
    width: 100%;
}

@import "./Facematch--medium";
