@use "../../../vars/vars.scss"as v;


.session{
    display: flex;
    height: 4.2rem;
    width: 100%;
    max-height: 5.5rem;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid v.$color-bordes;
    z-index:5;
    &__box {
        width: 77%;
        &--titulo {
            width: 100%;
            font-size: v.$font-letra-small;
            color: v.$color-letra;
            text-align: center;
        }
        &--contenido {
            width: 100%;
            text-align: center;
            color: v.$color-oscuro;
            font-size: v.$font-letra;
        }
    }

    &__input {
        width: 74%;
        display: flex;
        flex-direction: column;
    
        &--checkbox {
            height: 50%;
            width: 85%;
            display: inline-flex;
            align-items: center;
            align-content: flex-start;
            padding-left: 1.8rem;
            p{
                width: 100%;
                font-size: v.$font-letra-small;
            }
            input {
                margin: auto;
            }
        }
    }
   
}
.session__filters{
    width: 12rem;
    height: 10rem;
    position: absolute;
    z-index: 10;
    padding: 1rem;
    top: 13rem;
    border: 1px solid v.$color-bordes;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    .session__box{
        width: 100%;
        margin-bottom: 1rem;
    }
    .session__input--checkbox{
        padding-left: 0;
        p{
            width: 74%;
        }
    }
  

}   
.session__outelement{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 0;

}
