@use "../../../vars/vars.scss" as v;
.score {
  width: 100%;
  overflow-x: hidden;
  &__svg {
    z-index: 9;
    svg {
      width: 2rem;

      height: 100%;
      g {
        fill: v.$color-secndario-svgs;
      }
    }
    &__search {
      width: 100%;
      height: 100%;
    }
  }
}
.score__search {
  margin-left: 1.5rem;
  svg {
    width: 2rem;
    height: 100%;
    g {
      fill: v.$color-secndario-svgs;
    }
  }
}

.score--header {
  width: 100%;
  position: sticky;
  height: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: v.$color-bordes;
  z-index: -1;
  &--images {
    display: inline-flex;
    width: 20%;
  }
  &--logo {
    width: 15%;
    max-width: 7rem;
    padding: 1rem;
    height: 100%;
  }
  &--user {
    width: 42.5%;
    z-index: 3;
  }
  &--input {
    position: absolute;
    right: 8%;
    top: 0.6rem;
    z-index: 9;
    input {
      border: 1px solid v.$color-secndario;
      border-radius: 0.4rem;
      height: 4rem;
      color: v.$color-letra;
      z-index: 0;
      font-size: v.$font-letra;
      padding-left: 1rem;
    }
  }
  p {
    width: 70%;
    text-align: left;
    font-size: v.$font-letra;
    color: v.$color-letra;
    padding-top: 0.4rem;
  }
}
.Mobile__intento {
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.activado {
  overflow-y: hidden;
  height: auto;
  height: 100%;
  display: flex;
}
.scoreheader__input--closemodal {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
}
html, body {
    height: 100%;
}