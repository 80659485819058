body {
  height: 100%;
}
html, body {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-perspective: 1000;
  overflow: hidden;
}
@media (min-width: 992px) {
  * {
    box-sizing: content-box;
  }
  .score__containerdesk {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
  }

 
  .score__header {
    height: 5.3rem;
    width: 100%;
  }
  .score__main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 5;


  }
  @import "../Score/Items/items-desktop.scss";
  @import '../Score//Profile/Expediente/expediente--large';
  @import "../Score/Header/header-large";
  @import "../Score/Filters/filter-large.scss";
  @import "../Score/Session/session-large";
  @import "../Score/Mostrar/Mostrar";
  @import "../Score/ItemsDesktop/items-desktop";
  @import "../Score/Profile/Profile-large";
  @import "./Profile/Identidad/Facematch--large";
  @import "../../utilities/Modal.scss";
  @import "./ModalConfig/modalconfiglarge";
}
// filtros de definir mas espacio y simentrico
// border de mostrar en gris 
// mas pequeno 
// variables de tooltip highlights
// modal de calendario 
// reinciiar el scroll 
// lupa y usuario mas espacio
// scroll en tablet y mobile

