@use "../../../styles/vars/vars.scss" as v;

.config__options {
  width: 100%;
  max-width: none;
}
.config{
  background-color: v.$color-background;
  }
.config__header--space {
  display: block;
  width: 70%;
}
.config__modal {
  width: auto;
}
.cajatemas__header {
  display: flex;
}
.config__modal {
  width: 100%;
}
.config__container {
  display: block;
  width: 100%;
}
.configoptions__option{
    padding-left: 7%;
}