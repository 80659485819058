.expediente {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 29%;
  &--cajita {
    width: 100%;
    margin-top: 0;
    min-height: 7rem;
    height: 100%;
  }
  &--documentos {
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 5%;
    align-items: center;
    &--img {
      width: 50%;
    }
  }
  &--tabla {
    height: 9%;
    min-height: 6rem;
    display: flex;
    padding: 0 5%;
    align-items: center;
  }
}
.expediente--tablas {
  width: 100%;
  max-width: 100%;
}
.proceedings--map {
  min-height: 25rem;
  margin-top: 2rem;
}
.expediente--mapa {
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  padding:0 5%;
  padding-top: 2%;
}
.proceedings--map main {
  height: 25rem !important;
}
.proccedings__right {
  align-items: flex-start;
  padding: 0;
}
.expediente--tabla--derecha a:hover {
  text-decoration: underline;
}
.expediente--tabla--izquierda div {
  padding: 0;
  padding-left: 5%;
}
.expediente--tabla--izquierda {
  max-height: none;
  padding: 0;
}
.expediente--tabla--derecha {
  width: 49%;
  height: auto;
  max-height: none;
  padding: 0;
  padding-right: 1rem;
}
.expediente--tabla--derecha div {
  width: 100%;
  height: auto;
  max-height: none;
  padding: 0;
  padding-right: 1rem;
}
