@use "../../../vars/vars.scss" as v;

.footer {
    min-height: 6rem;
    img {
        width: 20%;
    }
    button {
        width: 78%;

        img {
            width: 30%;
            height: 60%;
        }
    }
    &--enpaginado {
        &--numeros {
            width: 10%;
            height: 10%;

            span {
                font-size: 2.5rem;
                margin: 0 2rem;
                font-weight: 600;
                color: v.$color-secndario-svgs;
            }
            p {
                font-size: 2rem;
            }
            &--izquierda {
                color: v.$color-secndario-svgs;
                font-size: v.$font-letra;
            }
            &--derecha {
                color: v.$color-letra;
                font-size: v.$font-letra;
            }
        }
        &--svg {
            width: 20%;
            fill: v.$color-secndario-svgs;
        }
    }
}
@media (min-width: 992px) {
    .footer {
        width: 100%;
        background-color: v.$color-claro;
        &--resultados {
            display: none;
        }
        &--enpaginado {
            margin: 0 auto;
            &--numeros {
                width: 50%;
                height: 90%;
                span {
                    font-size: 2.5rem;
                    margin: 0 1.2rem;
                    font-weight: 600;
                    color: v.$color-botones;
                }
                p {
                    font-size: 2.7rem;
                }
            }
            &--svg {
                width: 20%;
                fill: v.$color-secndario-svgs;
            }
        }
    }
}
